import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="DEFA web portal" />

    <div id="wrapper">
      <h1>DEFA web portal</h1>
      <div style={{ textAlign: 'center' }}>
        <p>Web portal used for installing, managing and realtime controlling DEFAs m2m products.</p>
        <img src="/images/defalink-customer.png" alt="Screenshot" style={{ marginTop: 20, width: '70%', maxWidth: 800 }} />
        <div style={{ fontSize: '3em', marginTop: '1em' }}><a target="_blank" rel="noopener noreferrer" href="http://mydefa.com">mydefa.com</a></div>
      </div>
    </div>
  </Layout>
);